#search-mask-component {
  width: 100%;
  background: #ffffff;
}

#hero {
  padding: 100px 0;
}

@media (max-width: 1199px) {
  #search-mask-component {
    width: 100%;
  }
}
